import axios from "axios";

export const getTicket = (data) => {
  return axios.post(process.env.TICKETING_URL, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRecurringTicket = (data) => {
  return axios.post(process.env.TICKETING_RECURRING_URL, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
