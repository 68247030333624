export const validateEmail = (value) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const validateGreekMobilePhone = (value) =>
  value && /^(\+30|0030)?69\d{8}$/.test(value);

export const validateGreekLandlinePhone = (value) =>
  value && /^(\+30|0030)?(2)\d{9}$/.test(value);

export const validateGreekPhone = (value) =>
  validateGreekMobilePhone(value) || validateGreekLandlinePhone(value);

export const validateAmountTwoDecimalsFormat = (value) =>
  value && /^\d+((\.{1}|,{1})\d{1,2})?[€]?$/.test(value);

export const validatePostalCode = (value) => value && value.length <= 16;
///^[12345678][0-9]{4}$/.test(value);

export const validateAddress = (value) =>
  value && /^[a-zα-ωίϊΐόάέύϋΰήώ0-9\s/:_().,+-]+$/i.test(value);

export const validateCity = (value) =>
  value && /^[a-zα-ωίϊΐόάέύϋΰήώ\s/:_().,+-]+$/i.test(value);

export const validateCardHolderName = (value) =>
  value && /^[a-z\s/:_().,+-]+$/i.test(value);

export const isNullOrWhitespace = (value) =>
  !value || value.trim().length === 0;

export const isAllowedUrl = (value) => {
  if (
    value.indexOf(process.env.WEBSITE_DOMAIN) > -1 ||
    value.indexOf(process.env.PAYMENT_PAGE_URL) > -1
  ) {
    return true;
  }
  const patterns = [
    /https:\/\/gray-forest-05a16c203\.azurestaticapps\.net/,
    /https:\/\/gray-forest-05a16c203(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
    /https:\/\/delightful-sea-0641b9b03\.azurestaticapps\.net/,
    /https:\/\/delightful-sea-0641b9b03(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
    /https:\/\/witty-island-0d5649403\.azurestaticapps\.net/,
    /https:\/\/witty-island-0d5649403(?:-\d+)?\.westeurope\.azurestaticapps\.net/,
    /http:\/\/localhost(?:-\d+)?/,
  ];

  const matchingUrl = patterns.filter((pattern) => pattern.test(value));

  return matchingUrl.length > 0;
};
