const removeKeyupListener = (elem) => {
  elem.removeEventListener("keyup", removeErrorClasses);
};

const removeErrorClasses = (event) => {
  event.currentTarget.classList.remove("error");
  document
    .querySelector(`label[for="${event.currentTarget.id}"]`)
    .classList.remove("error");

  removeKeyupListener(event.currentTarget);
};

export const handleErrorClassOnChange = (elem) => {
  elem.addEventListener("keyup", removeErrorClasses);
};

export const enableInputs = () => {
  const inputs = getInputFields();

  inputs.forEach((input) => {
    if (input.disabled !== true) {
      input.disabled = false;
    }
  });
};

export const togglePageLoader = (show) => {
  if (show) document.getElementById("page_loader").style.display = "flex";
  else document.getElementById("page_loader").style.display = "none";
};

export const toggleButtonLoader = (show) => {
  if (show) {
    document.querySelector("button#send span").style.display = "none";
    document.querySelector("button#send div").style.display = "flex";
    document.querySelector("button#send").disabled = true;
  } else {
    document.querySelector("button#send span").style.display = "block";
    document.querySelector("button#send div").style.display = "none";
    document.querySelector("button#send").disabled = false;
  }
};

export const removeErrors = (inputsArr, isRecurring = false) => {
  inputsArr.forEach((x) => {
    x.classList.remove("error");
    document.querySelector(`label[for="${x.id}"]`).classList.remove("error");
  });
  if (!isRecurring) {
    document.getElementById("payment_code_invalid").style.display = "none";
  }
  document.getElementById("cardholder_invalid").style.display = "none";
};

export const addErrorToFields = (errorFields) => {
  errorFields.forEach((x) => {
    const elem = document.querySelector(`[name="${x}"]`);
    if (elem) {
      elem.classList.add("error");
      handleErrorClassOnChange(elem);
    }
    const labelElem = document.querySelector(`label[for="${x}"]`);
    if (labelElem) labelElem.classList.add("error");

    if (x === "PaymentCode")
      document.getElementById("payment_code_invalid").style.display = "block";
    if (x === "CardholderName")
      document.getElementById("cardholder_invalid").style.display = "block";
    scrollTo(0, 0);
  });
};

export const fillForm = (formData) => {
  document.querySelector("#BillAddrCity").value = (
    formData.BillAddrCity ?? ""
  ).trim();
  document.querySelector("#BillAddrCountry").value = (
    formData.BillAddrCountry ?? ""
  ).trim();
  document.querySelector("#BillAddrLine1").value = (
    formData.BillAddrLine1 ?? ""
  ).trim();
  document.querySelector("#BillAddrState").value = (
    formData.BillAddrState ?? ""
  ).trim();
  document.querySelector("#BillAddrPostCode").value = (
    formData.BillAddrPostCode ?? ""
  ).trim();
  // document.querySelector("#CardholderName").value = (
  //   formData.CardholderName ?? ""
  // ).trim();
  document.querySelector("#HomePhone").value = (
    formData.HomePhone ?? ""
  ).trim();
  document.querySelector("#Email").value = (formData.Email ?? "").trim();
};

export const getInputFields = () => {
  const inputs = document.querySelectorAll(
    ".form-input input, .form-input select",
  );
  return inputs;
};

export const scrollTo = (top = 0, left = 0, element, behavior = "smooth") => {
  const scrollElement =
    element && typeof element.scrollTo === "function" ? element : window;

  if (scrollElement) {
    const isSmoothScrollSupported =
      "scrollBehavior" in document.documentElement.style;

    if (isSmoothScrollSupported) {
      scrollElement.scrollTo({
        top,
        left,
        behavior,
      });
    } else {
      scrollElement.scrollTo(top, left);
    }
  }
};

export const showErrorBox = () => {
  document.querySelector(".error-box").style.display = "flex";
  scrollTo(0, 0);
};

export const removeErrorBox = () => {
  document.querySelector(".error-box").style.display = "none";
};

export const showBankRedirectionDialog = () => {
  document.querySelector(".bank-redirection-dialog-overlay").style.display =
    "block";
  document.querySelector(".bank-redirection-dialog").style.display = "flex";
};

export const removeBankRedirectionDialog = () => {
  document.querySelector(".bank-redirection-dialog-overlay").style.display =
    "none";
  document.querySelector(".bank-redirection-dialog").style.display = "none";
};

export const showDelayAndRemoveBankRedirectionDialog = (
  callback = () => {},
) => {
  scrollTo(200, 0);
  showBankRedirectionDialog();
  setTimeout(() => {
    removeBankRedirectionDialog();
    callback();
  }, 2000);
};
