const states = [
  "ΑΙΤΩΛΟΚΑΡΝΑΝΙΑΣ",
  "ΑΡΓΟΛΙΔΑΣ",
  "ΑΡΚΑΔΙΑΣ",
  "ΑΡΤΑΣ",
  "ΑΤΤΙΚΗΣ",
  "ΑΧΑΙΑΣ",
  "ΒΟΙΩΤΙΑΣ",
  "ΓΡΕΒΕΝΩΝ",
  "ΔΡΑΜΑΣ",
  "ΔΩΔΕΚΑΝΗΣΟΥ",
  "ΕΒΡΟΥ",
  "ΕΥΒΟΙΑΣ",
  "ΕΥΡΥΤΑΝΙΑΣ",
  "ΖΑΚΥΝΘΟΥ",
  "ΗΛΕΙΑΣ",
  "ΗΜΑΘΙΑΣ",
  "ΗΡΑΚΛΕΙΟΥ",
  "ΘΕΣΠΡΩΤΙΑΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΙΩΑΝΝΙΝΩΝ",
  "ΚΑΒΑΛΑΣ",
  "ΚΑΡΔΙΤΣΑΣ",
  "ΚΑΣΤΟΡΙΑΣ",
  "ΚΕΡΚΥΡΑΣ",
  "ΚΕΦΑΛΛΗΝΙΑΣ",
  "ΚΙΛΚΙΣ",
  "ΚΟΖΑΝΗΣ",
  "ΚΟΡΙΝΘΙΑΣ",
  "ΚΥΚΛΑΔΩΝ",
  "ΛΑΚΩΝΙΑΣ",
  "ΛΑΡΙΣΑΣ",
  "ΛΑΣΙΘΙΟΥ",
  "ΛΕΣΒΟΥ",
  "ΛΕΥΚΑΔΑΣ",
  "ΜΑΓΝΗΣΙΑΣ",
  "ΜΕΣΣΗΝΙΑΣ",
  "ΞΑΝΘΗΣ",
  "ΠΕΛΛΗΣ",
  "ΠΙΕΡΙΑΣ",
  "ΠΡΕΒΕΖΑΣ",
  "ΡΕΘΥΜΝΗΣ",
  "ΡΟΔΟΠΗΣ",
  "ΣΑΜΟΥ",
  "ΣΕΡΡΩΝ",
  "ΤΡΙΚΑΛΩΝ",
  "ΦΘΙΩΤΙΔΑΣ",
  "ΦΛΩΡΙΝΑΣ",
  "ΦΩΚΙΔΑΣ",
  "ΧΑΛΚΙΔΙΚΗΣ",
  "ΧΑΝΙΩΝ",
  "ΧΙΟΥ",
];

function populateStatesSelect() {
  const selectElem = document.getElementById("BillAddrState");
  states.forEach((x) => {
    var option = document.createElement("option");
    option.text = x;
    option.value = x;
    selectElem.add(option);
  });
}

export { states, populateStatesSelect };
