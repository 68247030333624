const langs = {
  en: {
    paymentDetails: "Payment details",
    shippingAddress: "Shipping address",
    personalInformation: "Personal details",
    debtAmount: "Payment amount",
    code: "Payment code",
    city: "City",
    country: "Country",
    address: "Address",
    postalCode: "Postal Code",
    prefecture: "County",
    cardHolderName: "Card holder name",
    phone: "Phone number",
    email: "Email",
    terms: "Acceptance of terms of electronic transactions",
    payment: "Payment",
    paymentCodeInvalid:
      "The payment code that you input, does not correspond to an active counter",
    enaInfo: "The minimum activation amount of HERON EN.A is 100€.",
    cardholderNameInvalid:
      "The field only accepts English characters, lowercase and uppercase, space and the symbols: /:_().,+",
    participation: "Participation amount",
    technicalErrorTitle: "Technical Error",
    technicalErrorMessage: "Unfortunately an error occured",
    recurringInfo:
      "In order to setup the direct debit payment and confirm your card details, the bank will perform a zero-amount transaction.",
    continue: "Continue",
    bankRedirection: "Continue in a safe bank environment",
  },
  el: {
    paymentDetails: "Στοιχεία πληρωμής",
    shippingAddress: "Διεύθυνση αποστολής",
    personalInformation: "Προσωπικά στοιχεία",
    debtAmount: "Ποσό οφειλής",
    code: "Kωδικός πληρωμής",
    city: "Πόλη",
    country: "Χώρα",
    address: "Διεύθυνση",
    postalCode: "Ταχυδρομικός κώδικας",
    prefecture: "Νομός",
    cardHolderName: "Όνομα ιδιοκτήτη κάρτας",
    phone: "Τηλέφωνο",
    email: "Email",
    terms: "Αποδοχή όρων ηλεκτρονικών συναλλαγών",
    payment: "Πληρωμή",
    paymentCodeInvalid:
      "Ο κωδικός πληρωμής που εισήγαγες δεν αντιστοιχεί σε ενεργό μετρητή.",
    enaInfo: "Το ελάχιστο ποσό ενεργοποίησης του ΗΡΩΝ ΕΝ.Α είναι 100€.",
    cardholderNameInvalid:
      "Το πεδίο δέχεται μόνο αγγλικούς χαρακτήρες, πεζούς και κεφαλαίους, κενό και τα σύμβολα: /:_().,+-",
    participation: "Ποσό συμμετοχής",
    technicalErrorTitle: "Τεχνικό πρόβλημα",
    technicalErrorMessage: "Δυστυχώς προέκυψε κάποιο πρόβλημα.",
    recurringInfo:
      "Για την επιβεβαίωση των στοιχείων της νέας κάρτας σου, θα πραγματοποιηθεί μία μηδενική συναλλαγή από την τράπεζα.",
    continue: "Συνέχεια",
    bankRedirection: "Συνέχεια σε ασφαλές περιβάλλον τράπεζας",
  },
};

function getLanguage() {
  return (window.location?.href || "").indexOf("lang=en") > -1 ? "en" : "el";
}
function setLanguage(isRecurring = false) {
  // Find out the language
  const lang = getLanguage();

  document.getElementById("shipping-address-title").innerHTML =
    langs[lang].shippingAddress;
  document.getElementById("personal-information-title").innerHTML =
    langs[lang].personalInformation;

  document.getElementById("terms").nextElementSibling.innerHTML =
    langs[lang].terms;

  document.getElementById("cardholder_invalid").innerHTML =
    langs[lang].cardholderNameInvalid;

  document.querySelector(
    "a#terms_link",
  ).href = `${process.env.WEBSITE_URL}/${lang}/terms/online-payment-terms`;

  document.querySelector('label[for="BillAddrCity"]').innerHTML =
    langs[lang].city;
  document.querySelector('label[for="BillAddrCountry"]').innerHTML =
    langs[lang].country;
  document.querySelector('label[for="BillAddrLine1"]').innerHTML =
    langs[lang].address;
  document.querySelector('label[for="BillAddrPostCode"]').innerHTML =
    langs[lang].postalCode;
  document.querySelector('label[for="BillAddrState"]').innerHTML =
    langs[lang].prefecture;
  document.querySelector('label[for="CardholderName"]').innerHTML =
    langs[lang].cardHolderName;
  document.querySelector('label[for="HomePhone"]').innerHTML =
    langs[lang].phone;
  document.querySelector('label[for="Email"]').innerHTML = langs[lang].email;
  document.querySelector(".error-description h4").innerHTML =
    langs[lang].technicalErrorTitle;
  document.querySelector(".error-description p").innerHTML =
    langs[lang].technicalErrorMessage;
  document.querySelector(".bank-redirection-dialog p").innerHTML =
    langs[lang].bankRedirection;

  if (isRecurring) {
    setRecurringPaymentTranslations(lang);
  } else {
    setPaymentTranslations(lang);
  }
}

function setPaymentTranslations(lang) {
  document.getElementById("payment-details-title").innerHTML =
    langs[lang].paymentDetails;

  document.getElementById("payment_code_invalid").innerHTML =
    langs[lang].paymentCodeInvalid;
  document.getElementById("ena_info").innerHTML = langs[lang].enaInfo;
  document.querySelector('label[for="Amount"]').innerHTML =
    langs[lang].debtAmount;
  document.querySelector('label[for="PaymentCode"]').innerHTML =
    langs[lang].code;

  document.querySelector("button#send span").innerHTML = langs[lang].payment;
}

function setRecurringPaymentTranslations(lang) {
  document.getElementById("recurring_msg").innerHTML =
    langs[lang].recurringInfo;

  document.querySelector("button#send span").innerHTML = langs[lang].continue;
}

export { langs, setLanguage, getLanguage };
