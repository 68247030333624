import axios from "axios";
import { isNullOrWhitespace } from "../js/validators";

export const getUserDetails = (token, accountId) => {
  const query = isNullOrWhitespace(accountId) ? "" : `?accountId=${accountId}`;
  return axios.get(`${process.env.FORMFILL_URL}${query}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": process.env.OCP_APIM_SUBSCRIPTION_KEY,
      Authorization: token,
    },
  });
};
