import Big from "big.js";
import {
  validateEmail,
  validateGreekPhone,
  validateAmountTwoDecimalsFormat,
  validatePostalCode,
  isNullOrWhitespace,
  validateAddress,
  validateCity,
  validateCardHolderName,
} from "./validators";
import { commodities } from "./commodities";

export const validateForm = (formFields, isRecurring = false) => {
  const errorFields = [];

  if (!isRecurring) {
    if (!validateAmountTwoDecimalsFormat(formFields.Amount))
      errorFields.push("Amount");
    if (isNullOrWhitespace(formFields.PaymentCode))
      errorFields.push("PaymentCode");
  }

  if (!validateAddress(formFields.BillAddrLine1))
    errorFields.push("BillAddrLine1");
  if (!validatePostalCode(formFields.BillAddrPostCode))
    errorFields.push("BillAddrPostCode");
  if (!validateCity(formFields.BillAddrCity)) errorFields.push("BillAddrCity");
  if (isNullOrWhitespace(formFields.BillAddrState))
    errorFields.push("BillAddrState");

  if (!validateCardHolderName(formFields.CardholderName))
    errorFields.push("CardholderName");
  if (!validateGreekPhone(formFields.HomePhone)) errorFields.push("HomePhone");
  if (!validateEmail(formFields.Email)) errorFields.push("Email");

  return errorFields;
};

export const getAmountWithCurrency = (amount) =>
  amount ? `${new Big(amount).toFixed(2)}€`.replace(".", ",") : "";

export const getAmount = (amount) =>
  new Big(amount?.replace(",", ".").replace("€", "")).toNumber();

export const getPhone = (homePhone) =>
  `30-${
    homePhone.indexOf("+30") === 0
      ? homePhone.substring(3)
      : homePhone.indexOf("0030") === 0
      ? homePhone.substring(4)
      : homePhone
  }`;

export const populatePaymentKeys = (merchantRef, commodity) => {
  document.querySelector("input[name=MerchantReference]").value = merchantRef;
  document.querySelector("input[name=AcquirerId]").value =
    process.env.PAYMENT_ACQUIRERID;
  document.querySelector("input[name=MerchantId]").value =
    commodity === commodities.gas
      ? process.env.PAYMENT_MERCHANTID_GAS
      : commodity === commodities.energy
      ? process.env.PAYMENT_MERCHANTID_ENERGY
      : "";
  document.querySelector("input[name=PosId]").value =
    commodity === commodities.gas
      ? process.env.PAYMENT_POSID_GAS
      : commodity === commodities.energy
      ? process.env.PAYMENT_POSID_ENERGY
      : "";

  document.querySelector("input[name=User]").value = process.env.PAYMENT_USER;
};

export const populateRecurringPaymentKeys = (merchantRef, commodity) => {
  document.querySelector("input[name=MerchantReference]").value = merchantRef;
  document.querySelector("input[name=AcquirerId]").value =
    process.env.PAYMENT_ACQUIRERID;
  document.querySelector("input[name=MerchantId]").value =
    commodity === commodities.gas
      ? process.env.PAYMENT_MERCHANTID_RECURRING_GAS
      : commodity === commodities.energy
      ? process.env.PAYMENT_MERCHANTID_RECURRING_ENERGY
      : "";

  document.querySelector("input[name=PosId]").value =
    commodity === commodities.gas
      ? process.env.PAYMENT_POSID_RECURRING_GAS
      : commodity === commodities.energy
      ? process.env.PAYMENT_POSID_RECURRING_ENERGY
      : "";

  document.querySelector("input[name=User]").value = process.env.PAYMENT_USER;
};
